import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from 'contexts/auth';
import { EnvironmentProvider } from 'contexts/environment';
import { BreadcrumbsProvider } from '@HarrisonKeeling/design-system';
import App from './app';
import './index.css';

createRoot(document.getElementById('root')).render(
    <IntlProvider locale="en">
        <Helmet>
            <title>Technology for Lenders</title>
            <meta
                name="description"
                content="TFL digitizes property inspections for lenders. You get high fidelity ground truth. Your customers get hassle-free experiences."
            />
        </Helmet>
        <DndProvider backend={HTML5Backend}>
            <SnackbarProvider
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={1500}
            >
                <BreadcrumbsProvider>
                    <EnvironmentProvider>
                        <AuthProvider>
                            <App />
                        </AuthProvider>
                    </EnvironmentProvider>
                </BreadcrumbsProvider>
            </SnackbarProvider>
        </DndProvider>
    </IntlProvider>
);
